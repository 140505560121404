import * as React from "react";

import Select from "~/components/core/Select";
import SelectSubject from "~/components/core/SelectSubjects";
import { findSubjectPath } from "~/components/core/SelectSubjects/utils";
import SelectVariants from "~/components/core/SelectVariants/SelectVariants";
import AccountLayoutContext from "~/components/layouts/AccountLayout/AccountLayoutContext";
import {
  getSubjects,
  SCALE_VARIANTS,
  scaleFromString,
  SESSION_VARIANTS,
  sessionFromString
} from "~/constants/filters";
import { trackEvent } from "~/utils/segment";
import { struct } from "~/utils/struct";

import DashboardContext from "../DashboardContext";
import { useDashboardQueryState } from "../dashboardQueryState";

const DashboardCustomFilters: React.FC = () => {
  const [queryState, setQueryState] = useDashboardQueryState();

  const { subjects: allSubjects } = React.useContext(AccountLayoutContext);
  const {
    filter: { subjects, scale, lesson }
  } = React.useContext(DashboardContext);

  const [subjectsToSelect, setSubjectsToSelect] = React.useState<
    ISubjectGroup[] | undefined
  >();
  const [selectedSubjectsPaths, setSelectedSubjectsPaths] = React.useState(
    subjects
  );

  React.useEffect(() => {
    if (allSubjects.data) {
      setSubjectsToSelect(getSubjects(allSubjects.data));
      if (subjects && allSubjects.data) {
        const allPaths = subjects.reduce((acc, c) => {
          let subjPath: number[] | null = null;
          if (allSubjects.data) {
            subjPath = findSubjectPath(allSubjects.data, c);
          }
          if (subjPath) {
            subjPath.forEach(id => {
              if (!acc.includes(id)) {
                acc.push(id);
              }
            });
          }
          return acc;
        }, [] as number[]);
        setSelectedSubjectsPaths(allPaths);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSubjects]);

  React.useEffect(() => {
    if (!queryState.subjects) {
      setSelectedSubjectsPaths([]);
    }
  }, [queryState]);

  const handleChangeScale = React.useCallback(
    (value: string) => {
      setQueryState({ scale: scaleFromString(value) });
      trackEvent("change_scale_range", { type: scaleFromString(value) });
    },
    [setQueryState]
  );

  const handleChangeSessionType = React.useCallback(
    (value: string) => {
      setQueryState({ moderation: sessionFromString(value) });
      trackEvent("change_moderation_filter", {
        type: sessionFromString(value)
      });
    },
    [setQueryState]
  );

  const selectedSession = SESSION_VARIANTS.find(x => x.value === lesson);
  if (!selectedSession) {
    throw new Error(`invalid session ${lesson}`);
  }

  const selectedScale = SCALE_VARIANTS.find(x => x.value === scale);
  if (!selectedScale) {
    throw new Error(`invalid scale ${scale}`);
  }

  const handleSelectSubject = React.useCallback(
    (subjects: number[] | undefined) => {
      setQueryState({ subjects });
      setSelectedSubjectsPaths(subjects);
      trackEvent("change_subject_filter", {
        subject: subjects ? subjects.toString() : "all subjects"
      });
    },
    [setQueryState]
  );

  return (
    <div className="ml-0 sm:ml-[16px] mt-[16px] sm:mt-0 flex flex-col gap-[8px] items-start flex-grow sm:flex-row sm:items-center">
      {subjectsToSelect && (
        <SelectSubject
          selectedSubjectIds={selectedSubjectsPaths || []}
          allSubjects={subjectsToSelect}
          onChange={handleSelectSubject}
          multiSelect
        />
      )}
      <div className="flex flex-col items-start mt-[16px] sm:mt-0 sm:flex-row sm:items-center gap-[16px] flex-grow justify-end">
        <Select
          label="Frequency"
          options={SCALE_VARIANTS}
          onChange={handleChangeScale}
          selectedOption={selectedScale}
          className="w-[136px] frequencySelect"
          testidButton={struct.adminDash.queryFilters.frequency.menubutton}
          testidOption={struct.adminDash.queryFilters.frequency.option}
        />
        <SelectVariants
          onChange={handleChangeSessionType}
          selectedOption={selectedSession}
        />
      </div>
    </div>
  );
};

export default DashboardCustomFilters;
