import React from "react";
import { Placement, Step } from "react-joyride";

import DashboardGuideContext from "~/components/dashboard/DashboardGuide/DashboardGuideContext";

const placement: Placement = "top";
const props = {
  disableBeacon: true,
  disableOverlayClose: true,
  hideCloseButton: true,
  hideFooter: true,
  spotlightClicks: true,
  placement: placement,
  styles: {
    options: {
      zIndex: 9999
    }
  }
};

export function useDashboardGuideSteps(): Step[] {
  const {
    headerRef,
    filtersRef,
    generalReportsRef,
    subjectStatsRef,
    ratingsRef,
    powerUsersRef
  } = React.useContext(DashboardGuideContext);

  return [
    {
      ...props,
      target: headerRef?.current || "body",
      title: "Quick facts",
      content: `Track tutoring usage for the current school year: Discover how many students have accessed the platform and engaged in tutoring sessions. You'll also see a breakdown of live sessions versus Writing Labs for your school. Manage the school start date through Settings.`,
      placement: "bottom-start"
    },
    {
      ...props,
      target: filtersRef?.current || "body",
      title: "Filters",
      content: `Use filters to refine the usage reports and gain more targeted insights. Once you apply a filter, all the reports displayed below will update automatically to reflect your selections.`,
      spotlightPadding: 50,
      styles: {
        options: {
          zIndex: 9999
        },
        spotlight: {
          translate: "0px -20px"
        }
      }
    },
    {
      ...props,
      target: generalReportsRef?.current || "body",
      title: "Usage",
      content: `Utilize the usage reports to monitor student tutoring activity. These reports also allow you to track the volume of live sessions and Writing Labs, giving you comprehensive insights into student engagement.`
    },
    {
      ...props,
      target: subjectStatsRef?.current || "body",
      title: "Subjects",
      content: `Use the subject report to analyze tutoring demand across different subjects. Reveal valuable insights into students’ performance and identify areas where additional support may be needed.`
    },
    {
      ...props,
      target: ratingsRef?.current || "body",
      title: "Tutoring feedback",
      content: `Access ratings submitted by both students and tutors. Learn directly from the students about their experiences and gather insights into the effectiveness of the tutoring sessions.`
    },
    {
      ...props,
      target: powerUsersRef?.current || "body",
      title: "Power Users",
      content: `Identify your power users! Explore their tutoring activity and leverage their feedback to enhance and promote Pear Deck Tutor across your school.`
    }
  ];
}
