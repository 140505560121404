import { Popover, Transition } from "@headlessui/react";
import classNames from "classnames";
import React from "react";

import { menuTransitionClasses } from "~/components/layouts/AccountLayout/AccountHeader/AccountUser/UserMenu";
import { struct } from "~/utils/struct";

import { SubjectMenuItem } from "./SubjectMenuItem";
import { SubjectsMenuButton } from "./SubjectsMenuButton";
import { findParent, handleMultiSelect } from "./utils";

export interface ISelectSubjectProps {
  onChange: (id?: number[]) => void;
  allSubjects: ISubjectGroup[];
  selectedSubjectIds: number[];
  multiSelect?: boolean;
  // testidOption?: ISelectOptionTestId;
}

const SelectSubject: React.FC<ISelectSubjectProps> = ({
  onChange,
  allSubjects,
  selectedSubjectIds,
  multiSelect
}) => {
  const [currentSubj, setCurrentSubj] = React.useState<ISubjectGroup>(
    allSubjects[0]
  );

  // these 2 state objects control the view w/in the Popover.Panel
  const [category, setCategory] = React.useState<ISubjectGroup | null>(null);
  const [subjects, setSubjects] = React.useState<ISubjectGroup[]>(allSubjects);

  const findSubject = React.useCallback(
    (subjId: number, skipSetCategorySubjects?: boolean) => {
      const foundSubj = findParent(subjId, allSubjects);
      if (foundSubj) {
        setCurrentSubj(foundSubj.subj);
        if (!skipSetCategorySubjects) {
          setCategory(foundSubj.parent === "all" ? null : foundSubj.parent);
          setSubjects(
            foundSubj.parent === "all"
              ? allSubjects
              : foundSubj.parent.children || []
          );
        }
      }
    },
    [allSubjects]
  );

  React.useEffect(() => {
    if (selectedSubjectIds.length) {
      findSubject(
        selectedSubjectIds[selectedSubjectIds.length - 1],
        !!multiSelect
      );
    } else {
      setCurrentSubj(allSubjects[0]);
      setSubjects(allSubjects);
      setCategory(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubjectIds, findSubject, multiSelect]);

  const handleCategoryChange = (category: ISubjectGroup, goBack?: boolean) => {
    if (goBack) {
      findSubject(category.id);
    } else {
      setCategory(category);
      setSubjects(category.children || []);
    }
  };

  const handleSelectSubject = (subj?: ISubjectGroup) => {
    let subjectIds: number[] | undefined;

    if (!subj) {
      subjectIds = undefined;
    } else if (!multiSelect) {
      subjectIds = [subj.id];
    } else {
      subjectIds = handleMultiSelect(
        selectedSubjectIds,
        subj,
        category,
        allSubjects
      );
    }
    onChange(subjectIds);
  };

  return (
    <Popover className="relative w-[200px]">
      {({ close }) => (
        <>
          <SubjectsMenuButton
            label={
              multiSelect && selectedSubjectIds.length > 3
                ? "Multiple subjects"
                : currentSubj.name || "All subjects"
            }
          />

          <Transition as={React.Fragment} {...menuTransitionClasses}>
            <Popover.Panel
              className={classNames(
                "absolute z-10 border border-cream-300 flex w-[283px] max-h-[387px] overflow-y-auto",
                "bg-white rounded-[4px] outline-none shadow-default top-[72px] left-[-1px]"
              )}
            >
              <div className="w-full flex flex-col paragraph py-[6px]">
                <SubjectMenuItem
                  subj={category || subjects[0]}
                  isCategory={!!category}
                  onSelect={() => {
                    if (category) {
                      handleCategoryChange(category, true);
                    } else {
                      if (!multiSelect) {
                        close();
                      }
                      // is all subjects
                      handleSelectSubject();
                    }
                  }}
                  selectedSubjectIds={!selectedSubjectIds.length ? [-1] : []}
                  testid={
                    category
                      ? struct.adminDash.queryFilters.subjects.goBack
                      : struct.adminDash.queryFilters.subjects.allSubjects
                  }
                />

                <div className="border-t border-cream-300 my-[6px]" />

                {subjects
                  // removes "All Subjects" from the list
                  .slice(category ? 0 : 1, subjects.length)
                  .map((subj, i) => (
                    <SubjectMenuItem
                      key={`${i}-${subj.id}}`}
                      subj={subj}
                      onSelect={() => {
                        if (!multiSelect) {
                          close();
                        }
                        handleSelectSubject(subj);
                      }}
                      selectChildren={
                        subj.children && subj.children.length === 0
                          ? undefined
                          : () => handleCategoryChange(subj)
                      }
                      multiSelect={multiSelect}
                      selectedSubjectIds={selectedSubjectIds}
                      testid={
                        subj.children && subj.children.length
                          ? struct.adminDash.queryFilters.subjects.selectCategory(
                              subj.name
                            )
                          : struct.adminDash.queryFilters.subjects.selectSubject(
                              subj.name
                            )
                      }
                    />
                  ))}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default SelectSubject;
