import classNames from "classnames";
import dayjs from "dayjs";
import * as React from "react";

import { SCALE } from "~/constants/filters";
import { useReduxSelector } from "~/redux/hooks";

import { useDashboardQueryState } from "../dashboardQueryState";

const ChartXAxisLabels: React.FC<{ id: string; className?: string }> = ({
  id,
  className
}) => {
  const { xAxisLabels, chartsLoading, dataSetLength } = useReduxSelector(
    state => ({
      xAxisLabels: state.charts.xAxisLabels,
      chartsLoading: state.charts.chartsLoading,
      dataSetLength: state.charts.dataSetLength
    })
  );

  const [{ scale }] = useDashboardQueryState();

  let dateFormat = "MMM D";
  if (scale === SCALE.monthly) {
    dateFormat = "MMM YYYY";
  } else if (scale === SCALE.weekly) {
    dateFormat = "MMM D";
  } else if (scale === SCALE.hourly) {
    dateFormat = "h A";
  }

  if (xAxisLabels.length === 0 || chartsLoading) {
    return null;
  }

  return (
    <div
      className={classNames(
        "flex explanatoryText justify-end self-end !text-gray-600",
        className
      )}
    >
      <div className="flex justify-between w-full">
        {xAxisLabels.map((tick, idx) => {
          return (
            <div
              key={`${id}-${idx}-${tick.value}`}
              className="truncate whitespace-nowrap max-w-40px"
            >
              {tick.label ? tick.label : dayjs(tick.value).format(dateFormat)}
            </div>
          );
        })}
        {xAxisLabels.length !== dataSetLength &&
          xAxisLabels.length > 9 &&
          xAxisLabels.length < 12 && <div className="w-[1px]" />}
      </div>
    </div>
  );
};

export default ChartXAxisLabels;
