import { css, Global } from "@emotion/react";
import Modal from "@material-ui/core/Modal";
import { useTheme } from "@material-ui/core/styles";
import * as React from "react";
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from "react-joyride";

import { useRequiredAuthContext } from "~/components/auth/common";
import { Button } from "~/components/core/Button";
import { authenticatedPatch } from "~/utils/http";

import DashboardGuideStep from "./DashboardGuideStep";
import { useDashboardGuideSteps } from "./useDashboardGuideSteps";

const arrowStyle = css`
  .__floater__arrow {
    display: none;
  }
`;

const DashboardGuide: React.FC = (): JSX.Element | null => {
  const { user, setUser, accessToken } = useRequiredAuthContext();
  const [run, setRun] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const theme = useTheme();
  const steps = useDashboardGuideSteps();

  const handleRunTutor = (): void => setRun(true);

  const handleSkipTutor = async (): Promise<void> => {
    authenticatedPatch(
      "/api/v1/account/me/",
      {
        introGuidePassed: true
      },
      accessToken
    );
    setUser({
      ...user,
      introGuidePassed: true
    });
    setRun(false);
  };

  const handleNextStep = ({
    action,
    index,
    status,
    type
  }: CallBackProps): void => {
    const { STEP_AFTER, TARGET_NOT_FOUND } = EVENTS;
    const { FINISHED, SKIPPED } = STATUS;

    if (type === STEP_AFTER || type === TARGET_NOT_FOUND) {
      setStep(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if (status === FINISHED || status === SKIPPED) {
      handleSkipTutor();
    }
  };

  if (!user || user?.introGuidePassed) return null;

  return (
    <>
      <Global styles={arrowStyle} />
      <Modal
        open={!run}
        className="flex flex-col items-center justify-center w-full h-full max-w-[500px] mx-auto"
      >
        <div className="outline-none flex flex-col bg-white paragraph p-[40px] rounded-[4px]">
          <div className="inputLabel">Welcome to the Admin Dashboard!</div>
          <span className="mt-[12px]">
            We'll guide you through the Admin Dashboard, where you'll discover
            our advanced tutoring reporting tools. These tools will provide you
            with powerful insights into Pear Deck Tutor usage and performance at
            your school.
          </span>
          <div className="flex gap-[16px] justify-end mt-[32px]">
            <Button variant="secondaryGray" action={handleSkipTutor}>
              Skip tutorial
            </Button>
            <Button variant="primary" action={handleRunTutor}>
              Get started
            </Button>
          </div>
        </div>
      </Modal>
      <Joyride
        styles={{ options: { zIndex: theme.zIndex.tooltip + 1 } }}
        callback={handleNextStep}
        tooltipComponent={DashboardGuideStep}
        scrollToFirstStep
        showProgress
        steps={steps}
        stepIndex={step}
        run={run}
        continuous
      />
    </>
  );
};

export default DashboardGuide;
