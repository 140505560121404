import classNames from "classnames";
import React from "react";

import { useRequiredAuthContext } from "~/components/auth/common";
import { ButtonLink } from "~/components/core/Button";
import ClarificationIcon from "~/components/core/ClarificationIcon";
import DashboardContext from "~/components/dashboard/DashboardContext";
import { DashboardApiQuery } from "~/utils/http";
import { localeFormat } from "~/utils/numbers";
import { schoolYearFilterLabel } from "~/utils/schoolYearHelpers";
import { trackEvent } from "~/utils/segment";

import { ChartChange } from "./ChartChange";

export interface DashboardChartInfoProps {
  total?: number;
  title: string;
  link?: string;
  clarification?: JSX.Element | string;
  legend?: JSX.Element;
  testid?: {
    range: React.TestID;
    noData?: React.TestID;
    resetFilters?: React.ButtonTestID;
    resources?: React.ButtonTestID;
    error: React.TestID;
  };
  prevApiQuery?: DashboardApiQuery;
  endpoint:
    | "active_students"
    | "live_sessions"
    | "wl_sessions"
    | "comparison_sessions";
}

const DashboardChartInfo: React.FC<DashboardChartInfoProps> = ({
  total,
  title,
  link,
  clarification,
  testid,
  legend,
  prevApiQuery,
  endpoint
}) => {
  const { schoolYearStartDate } = useRequiredAuthContext();
  const { selectedPeriod } = React.useContext(DashboardContext);
  const selectedPeriodTitle = selectedPeriod?.title
    ? schoolYearFilterLabel(selectedPeriod.title, schoolYearStartDate)
    : "";

  return (
    <div className="flex flex-col gap-[8px] chart-details relative">
      <div className="flex flex-col gap-[8px] chart-data">
        <div className="flex gap-[16px] items-center">
          <div className="h2 line-clamp-1 max-w-[100%] !text-[32px] !leading-[32px] h-[32px]">
            {localeFormat(total || 0)}
          </div>
        </div>

        <div className={classNames("flex flex-col", legend && "chart-data")}>
          <div>
            <div className="paragraph flex gap-[4px] items-center">
              {title}
              <ClarificationIcon
                clarification={clarification}
                placement="right"
              />
            </div>
            <div
              className="!text-gray-600 explanatoryText"
              data-testid={testid?.range}
            >
              {selectedPeriodTitle}
            </div>
          </div>
          {legend && legend}
        </div>

        {prevApiQuery && (
          <ChartChange
            currentTotal={total}
            apiQuery={prevApiQuery}
            endpoint={endpoint}
          />
        )}
      </div>

      {(title === "Live sessions" || title === "Writing Lab reviews") && (
        <ButtonLink
          variant="wrapper"
          href={link || ""}
          className={classNames("paragraph link", !link && "invisible")}
          action={() => {
            if (title === "Live sessions") {
              trackEvent("graph_live_view_details_click", undefined);
            } else if (title === "Writing Lab reviews") {
              trackEvent("graph_wl_view_details_click", undefined);
            }
          }}
          disabled={!link}
        >
          View details
        </ButtonLink>
      )}
    </div>
  );
};

export default DashboardChartInfo;
