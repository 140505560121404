import dayjs from "dayjs";
import * as React from "react";

import { Button, ButtonLink } from "~/components/core/Button";
import { DATE_FORMAT } from "~/constants/dates";
import ROUTES from "~/constants/routes";
import { trackEvent } from "~/utils/segment";

interface IProps {
  lessons: boolean;
  quotes: IQuote[];
  resetFilters: () => void;
}

export const Quotes: React.FC<IProps> = props => {
  const { lessons, quotes } = props;
  if (!quotes.length) {
    return <NoData {...props} />;
  }

  return (
    <>
      {quotes.map((quote, index) => {
        const params = new URLSearchParams({ search: quote.student });
        return (
          <div
            key={index}
            className="my-[16px] border-l-[6px] border-cream-300 flex flex-col pl-[16px] gap-[12px] paragraph"
          >
            <div>{`"${quote.quote}"`}</div>
            <div>
              -{" "}
              <ButtonLink
                href={`${ROUTES.students}?${params.toString()}`}
                action={() =>
                  trackEvent("student_quote_name_click", {
                    name: quote.student
                  })
                }
                variant="wrapper"
                className="link paragraph"
              >
                {quote.student}
              </ButtonLink>
              ,{lessons ? ` ${quote.subjectName} session` : ""} on{" "}
              {dayjs(quote.createdAt).format(DATE_FORMAT)}
            </div>
          </div>
        );
      })}
    </>
  );
};

const NoData: React.FC<IProps> = ({ resetFilters, lessons }) => {
  const handleReset = () => {
    if (resetFilters) {
      resetFilters();
      trackEvent("reset_all_filters", undefined);
    }
  };

  const track = () => {
    trackEvent(
      lessons ? "student_quote_view_ll_click" : "student_quote_view_wl_click",
      undefined
    );
  };

  return (
    <div className="flex flex-col gap-[16px] mt-[32px] border-l-[6px] border-cream-300 pl-[16px]">
      <div className="subtitle1">No highlighted student quotes yet</div>
      <div className="paragraph">
        <div>
          Broaden or reset your filters, or review any completed reviews on the{" "}
          <ButtonLink
            href={lessons ? ROUTES.lessons : ROUTES.writingLabs}
            action={track}
            variant="wrapper"
            className="paragraph link"
          >
            {lessons ? "Live Tutoring" : "Writing Lab"}
          </ButtonLink>{" "}
          page.
        </div>
      </div>
      <div className="flex gap-[8px] items-center">
        <Button variant="secondary" action={handleReset}>
          Reset all filters
        </Button>
        <ButtonLink
          href={lessons ? ROUTES.lessons : ROUTES.writingLabs}
          action={track}
          variant="text"
        >
          View {lessons ? "Live Tutoring sessions" : "Writing Lab reviews"}
        </ButtonLink>
      </div>
    </div>
  );
};
