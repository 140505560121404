import { useCallback, useEffect, useRef } from "react";

export const useIfMounted: () => (
  callback: () => void,
  debug?: string
) => void = () => {
  const isMounted = useRef(true);
  useEffect(
    () => (): void => {
      isMounted.current = false;
    },
    []
  );

  const ifMounted: (callback: () => void, debug?: string) => void = useCallback(
    (callback, debug) => {
      if (!isMounted.current && debug) {
        console.log("Not mounted, skip", debug);
      }
      if (isMounted.current && callback) {
        if (debug) {
          console.log("Mounted, execute", debug);
        }
        callback();
      }
    },
    []
  );

  return ifMounted;
};
