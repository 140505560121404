import createDebug from "debug";
import * as React from "react";

// import useDebounce from "./useDebounce";

const debug = createDebug("dashboard:intersection");

export function useIntersectionObserver<T extends HTMLElement = HTMLElement>(
  debugName?: string
  // debounceDelay = 200
): [React.Dispatch<React.SetStateAction<T | null>>, boolean] {
  const [isVisible, setIsVisible] = React.useState(false);
  const [ref, setRef] = React.useState<T | null>(null);
  const isVisibleRef = React.useRef(false);
  const debouncedIsVisible = isVisible; // useDebounce(isVisible, debounceDelay);
  const handleIntersection = React.useCallback(
    (entries: IntersectionObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          if (!isVisibleRef.current) {
            if (debugName) {
              debug("useIntersectionObserver: set visible %s", debugName);
            }
            isVisibleRef.current = true;
            setIsVisible(true);
          }
          return;
        }
      }
      if (isVisibleRef.current) {
        if (debugName) {
          debug("useIntersectionObserver: set invisible %s", debugName);
        }
        isVisibleRef.current = false;
        setIsVisible(false);
      }
    },
    [setIsVisible, debugName]
  );
  const observer = React.useMemo(
    () => new IntersectionObserver(handleIntersection, { threshold: 0.1 }),
    [handleIntersection]
  );
  React.useLayoutEffect(() => {
    if (ref) {
      observer.observe(ref);
      return (): void => observer.unobserve(ref);
    }
  }, [ref, observer, handleIntersection]);

  return [setRef, debouncedIsVisible];
}
