import { Popover } from "@headlessui/react";
import ArrowIcon from "@material-design-icons/svg/outlined/keyboard_arrow_down.svg";
import React from "react";
import { useId } from "react-aria";

import { struct } from "~/utils/struct";

interface IProps {
  label: string;
}

export const SubjectsMenuButton: React.FC<IProps> = ({ label }) => {
  const buttonId = useId();
  const labelId = useId();
  return (
    <>
      <label id={labelId} htmlFor={buttonId} className="inputLabel">
        Subject
      </label>
      <div className="flex mt-[8px]">
        <Popover.Button
          as="div"
          tabIndex={0}
          className="w-[200px] formInput formInputBorderSimpleStyle flex items-center justify-between p-[8px]"
          id={buttonId}
          data-testid={struct.adminDash.queryFilters.subjects.menubutton}
        >
          <span className="max-w-[152px] truncate">{label}</span>
          <ArrowIcon
            aria-hidden
            className="w-[24px] h-[24px] pointer-events-none transition duration-200 ui-open:rotate-180"
          />
        </Popover.Button>
      </div>
    </>
  );
};
