import classNames from "classnames";
import * as React from "react";

import { ButtonLink } from "~/components/core/Button";
import Rating from "~/components/core/Rating/Rating";
import { customPeriods } from "~/constants/filters";
import ROUTES from "~/constants/routes";
import { trackEvent } from "~/utils/segment";

import { useDashboardQueryState } from "../dashboardQueryState";

interface IProps {
  lessons: boolean;
  data?: IRatingsData;
  isTutor?: boolean;
}

export const Ratings: React.FC<IProps> = ({ data, lessons, isTutor }) => {
  const [
    { fromDt, tillDt, period, moderation, subjects }
  ] = useDashboardQueryState();

  const params = React.useCallback(
    (rating: string) => {
      const params = new URLSearchParams({
        period: period,
        lesson: moderation || undefined
      });
      if (subjects && lessons) {
        params.append("subject", subjects.join(","));
      }
      if (customPeriods.includes(period) && tillDt) {
        params.append("tillDt", tillDt.toISOString());
      }
      if (customPeriods.includes(period) && fromDt) {
        params.append("fromDt", fromDt.toISOString());
      }
      if (isTutor) {
        params.append("tutorRating", rating);
      } else {
        params.append("studentRating", rating);
      }
      return `?${params.toString()}`;
    },
    [period, fromDt, tillDt, moderation, subjects, isTutor, lessons]
  );

  return (
    <div className="flex flex-col paragraph mr-[24px] w-full">
      <div className="flex items-center h1 leading-[32px] text-[32px]">
        {data?.averageRating ? data.averageRating.toFixed(2) : "-"}
        <span className="font-normal mx-[10px] paragraph">out of</span> 5
      </div>

      <div className="mt-[4px] flex gap-[8px] items-center">
        <Rating rating={data?.averageRating || 0} />
        <div>
          {data?.total || 0} review{data?.total === 1 ? "" : "s"}
        </div>
      </div>

      <div className="mt-[24px] flex flex-col gap-[8px] w-full">
        <div className="inputLabel">Rating breakdown</div>
        {[5, 4, 3, 2, 1].map(rating => {
          const ratingKey = `s${rating}`;
          const stars = data ? data[ratingKey as keyof IRatingsData] : 0;
          return (
            <div key={rating} className="flex gap-[8px] items-center">
              {typeof stars === "number" && stars > 0 ? (
                <ButtonLink
                  href={`${
                    lessons ? ROUTES.lessons : ROUTES.writingLabs
                  }${params(rating.toString())}`}
                  variant="wrapper"
                  className="link w-[40px] whitespace-nowrap"
                  action={() =>
                    trackEvent(
                      isTutor ? "tutor_rating_click" : "student_rating_click",
                      { rating }
                    )
                  }
                >
                  {rating} star
                </ButtonLink>
              ) : (
                <div className="w-[40px]">{rating} star</div>
              )}

              <div className="w-[calc(100%-80px)] h-[12px] bg-goldStar-100 rounded-[8px]">
                {typeof stars === "number" &&
                  stars > 0 &&
                  data &&
                  data.total > 0 && (
                    <div
                      className={classNames(
                        "h-[12px] bg-goldStar-500 rounded-l-[8px]",
                        stars === data.total && "rounded-r-[8px]"
                      )}
                      style={{ width: `${(stars / data.total) * 100}%` }}
                    />
                  )}
              </div>

              <div>{stars}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
