import dayjs from "dayjs";
import React, { useRef } from "react";

import { PERIOD } from "~/constants/dates";
import {
  RANGE_GETTERS,
  SCALE,
  scaleFromString,
  SESSION,
  sessionFromString
} from "~/constants/filters";
import { DateOrNull } from "~/declarations/filters";
import { DashboardApiQuery } from "~/utils/http";
import { useIntersectionObserver } from "~/utils/useIntersectionObserver";
import {
  queryStateItem,
  TypeFromQueryStateConfig,
  useQueryState,
  ValueSetter
} from "~/utils/useQueryState";

import { useRequiredAuthContext } from "../auth/common";

const DASHBOARD_QUERY_STATE_CONFIG = {
  scale: queryStateItem<SCALE>(
    v => (v === SCALE.daily ? undefined : SCALE[v]),
    q => {
      if (q) {
        try {
          return scaleFromString(q);
        } catch {}
      }
      return SCALE.daily;
    }
  ),
  moderation: queryStateItem(
    (v: SESSION) => (v === SESSION.all ? undefined : "moderated"),
    q => {
      if (q) {
        try {
          return sessionFromString(q);
        } catch {}
      }
      return SESSION.all;
    }
  ),
  // subject: queryStateItem(
  //   (v: number | null) => v?.toFixed(0) ?? undefined,
  //   q => (q && parseInt(q, 10)) || null
  // ),
  subjects: queryStateItem(
    (v: number[] | undefined) => (v?.length ? v.join(",") : undefined),
    q => {
      if (q?.length) {
        const items = q
          .split(",")
          .map(x => parseInt(x))
          .filter(x => !!x);
        if (items.length) {
          return items;
        }
      }
      return undefined;
    }
  ),
  period: queryStateItem(
    (v: PERIOD) => (v === PERIOD.last30Days ? undefined : PERIOD[v]),
    q => {
      switch (q) {
        case "last7Days":
          return PERIOD.last7Days;
        case "last90Days":
          return PERIOD.last90Days;
        case "last365Days":
          return PERIOD.last365Days;
        case "allTime":
          return PERIOD.allTime;
        case "currentYear":
          return PERIOD.currentYear;
        case "previousYear":
          return PERIOD.previousYear;
        case "custom":
          return PERIOD.custom;
        default:
          return PERIOD.last30Days;
      }
    }
  ),
  fromDt: queryStateItem(
    (v: DateOrNull) => v?.toISOString() ?? undefined,
    q => (q?.length ? dayjs(q) : null)
  ),
  tillDt: queryStateItem(
    (v: DateOrNull) => v?.toISOString() ?? undefined,
    q => (q?.length ? dayjs(q) : null)
  )
};

type DashboardQueryContextType = [
  TypeFromQueryStateConfig<typeof DASHBOARD_QUERY_STATE_CONFIG>,
  ValueSetter<typeof DASHBOARD_QUERY_STATE_CONFIG>,
  DashboardApiQuery
];

const DashboardQueryStateContext = React.createContext<
  DashboardQueryContextType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
>(undefined as any);

export function useDashboardQueryState() {
  const context = React.useContext(DashboardQueryStateContext);
  if (typeof context === "undefined") {
    throw new Error("DashboardQueryContext isnt available");
  }
  return context;
}

export function useDashboardApiQuery() {
  const [, , apiQuery] = useDashboardQueryState();
  return apiQuery;
}

export const DashboardQueryStateProvider: React.FC = ({ children }) => {
  const { selectedUmbrella } = useRequiredAuthContext();
  const [queryState, setQueryState] = useQueryState(
    DASHBOARD_QUERY_STATE_CONFIG
  );
  const contextValue = React.useMemo<DashboardQueryContextType>(() => {
    const presetRange = RANGE_GETTERS[queryState.period];
    const range: [DateOrNull, DateOrNull] = presetRange
      ? presetRange()
      : [queryState.fromDt, queryState.tillDt];

    return [
      queryState,
      setQueryState,
      {
        lessons: SESSION[queryState.moderation],
        umbrellaAccountId: selectedUmbrella.umbrellaAccName,
        fromDt: range[0]?.toISOString() ?? "",
        tillDt: range[1]?.toISOString() ?? "",
        scale: SCALE[queryState.scale],
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        subjects: queryState.subjects
          ? queryState.subjects.toString()
          : undefined
      }
    ];
  }, [queryState, selectedUmbrella.umbrellaAccName, setQueryState]);
  return (
    <DashboardQueryStateContext.Provider value={contextValue}>
      {children}
    </DashboardQueryStateContext.Provider>
  );
};

export type DashboardLazyWidgetProps = {
  apiQuery: DashboardApiQuery;
  visible: boolean;
};

export type DashboardLazyWidgetImpl = React.ForwardRefExoticComponent<
  DashboardLazyWidgetProps & React.RefAttributes<HTMLElement>
>;

export function useDashboardLazy(intersecionDebugName?: string) {
  const apiQuery = useDashboardApiQuery();
  const frozenApiQuery = useRef(apiQuery);
  const [ref, visible] = useIntersectionObserver(intersecionDebugName);
  if (visible) {
    frozenApiQuery.current = apiQuery;
  }
  const showContent = React.useRef(visible);
  if (visible) {
    showContent.current = true;
  }
  return {
    visible,
    ref,
    showContent: showContent.current,
    apiQuery: frozenApiQuery.current
  };
}

type LazyDashboardComponentProps = {
  intersecionDebugName?: string;
  placeholder: React.ReactElement;
  render: (
    apiQuery: DashboardApiQuery,
    ref: (value: HTMLElement | null) => void,
    visible: boolean
  ) => JSX.Element;
};

export function LazyDashboardComponent({
  intersecionDebugName,
  render,
  placeholder
}: LazyDashboardComponentProps) {
  const { showContent, visible, ref, apiQuery } = useDashboardLazy(
    intersecionDebugName
  );
  if (!showContent) {
    return React.cloneElement(placeholder, { ref });
  }
  return render(apiQuery, ref, visible);
}
