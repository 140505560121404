import { Range } from "~/declarations/filters";
import { schoolYearFilterLabel } from "~/utils/schoolYearHelpers";

const labels = {
  "Last 7 days": "the last 7 days",
  "Last 30 days": "the last 30 days",
  "Last 90 days": "the last 90 days",
  "Last 365 days": "the last 365 days",
  "All Time": "all time",
  "Current school year": "",
  "Previous school year": "",
  Custom: ""
};

export const dateFilterLabel = (label: string, date: string, range: Range) => {
  const schoolYearLabel = schoolYearFilterLabel(label, date);
  if (
    schoolYearLabel.includes("Current") ||
    schoolYearLabel.includes("Previous")
  ) {
    return `the ${schoolYearLabel}`.toLowerCase();
  } else if (label === "Custom") {
    return `${range[0]?.format("MMM D, YYYY")} - ${range[1]?.format(
      "MMM D, YYYY"
    )}`;
  } else {
    return labels[label as keyof typeof labels];
  }
};
