import React from "react";

import { useRequiredAuthContext } from "~/components/auth/common";
import { WidgetContentNew } from "~/components/core/WidgetCard/WidgetCardContent";
import { customPeriods, getPreviousPeriod } from "~/constants/filters";
import ROUTES from "~/constants/routes";
import { useReduxSelector } from "~/redux/hooks";
import { useAuthenticatedFetch } from "~/utils/http";
import { trackEvent } from "~/utils/segment";
import { struct } from "~/utils/struct";

import { DashboardAreaChart } from "../AreaChart";
import { createDashboardLazyWidgetNew } from "../createDashboardLazyWidgetNew";
import {
  ChartXAxisLabels,
  DashboardChartInfo
} from "../DashboardChartComponents";
import { useDashboardQueryState } from "../dashboardQueryState";

const DashboardLiveTutoringTotalCompletedChart = createDashboardLazyWidgetNew({
  displayName: "DashboardLiveTutoringTotalCompletedChart",
  // testid: struct.adminDash.dashboard.tutoring.completedChart,
  useRender: (apiQuery, resetFilters) => {
    const cumulative = useReduxSelector(state => state.charts.cumulative);

    const { schoolYearStartDate } = useRequiredAuthContext();

    const { data, loading, error } = useAuthenticatedFetch(
      "/api/v1/charts/live_sessions/",
      apiQuery,
      true
    );

    const [
      { fromDt, tillDt, period, moderation, subjects }
    ] = useDashboardQueryState();

    const prevApiQuery = React.useMemo(
      () => getPreviousPeriod(apiQuery, period, schoolYearStartDate),
      [apiQuery, period, schoolYearStartDate]
    );

    const params = React.useMemo(() => {
      const params = new URLSearchParams({
        period: period,
        lesson: moderation || undefined
      });
      if (subjects) {
        params.append("subject", subjects.join(","));
      }
      if (customPeriods.includes(period) && tillDt) {
        params.append("tillDt", tillDt.toISOString());
      }
      if (customPeriods.includes(period) && fromDt) {
        params.append("fromDt", fromDt.toISOString());
      }
      return `?${params.toString()}`;
    }, [period, fromDt, tillDt, moderation, subjects]);

    React.useEffect(() => trackEvent("ll_load_completed_chart", undefined), []);

    return (
      <>
        <DashboardChartInfo
          total={
            data?.length ? data.reduce((a, c) => a + c.value, 0) : undefined
          }
          title="Live sessions"
          clarification={
            <div>
              <span className="font-semibold">Live sessions</span> are the
              number of completed sessions with a live tutor.
            </div>
          }
          link={!!data?.length ? `${ROUTES.lessons}${params}` : undefined}
          prevApiQuery={prevApiQuery}
          endpoint="live_sessions"
        />

        <WidgetContentNew
          loading={loading}
          hasData={!!data?.length}
          error={error}
          resetFilters={resetFilters}
          className="w-[80%] !h-[110px] !m-0"
          testid={struct.adminDash.dashboard.tutoring.completedChart}
        >
          <div className="h-auto w-[80%] graph">
            <DashboardAreaChart
              apiQuery={apiQuery}
              data={data}
              label="Sessions"
              cumulative={cumulative}
            />
            <ChartXAxisLabels id="ll" className="hidden w-full graphs-show" />
          </div>
        </WidgetContentNew>
      </>
    );
  }
});

export default DashboardLiveTutoringTotalCompletedChart;
