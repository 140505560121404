import { SCALE } from "~/constants/filters";
import { Range } from "~/declarations/filters";

const RESTRICTION_THRESHOLDS = [
  { threshold: 365, restricted: [SCALE.hourly, SCALE.daily, SCALE.weekly] },
  { threshold: 180, restricted: [SCALE.hourly, SCALE.daily] },
  { threshold: 30, restricted: [SCALE.hourly] }
];

const daysInRange = (range: Range): number => {
  if (range[0] && range[1]) {
    const date1 = range[0];
    const date2 = range[1];

    return date2.diff(date1, "day");
  }

  return 0;
};

const EMPTY: SCALE[] = [];

export default function useScaleRestrictions(
  range: Range
): SCALE[] | undefined {
  const rangeDays = daysInRange(range);
  const foundRestriction = RESTRICTION_THRESHOLDS.find(
    ({ threshold }) => rangeDays > threshold
  );
  if (foundRestriction) {
    return foundRestriction.restricted;
  }
  return EMPTY;
}
