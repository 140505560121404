import * as React from "react";

import { AuthWrapper } from "~/components/auth/AuthWrapper";
import Dashboard from "~/components/dashboard/Dashboard";
import { DashboardQueryStateProvider } from "~/components/dashboard/dashboardQueryState";
import AccountLayout from "~/components/layouts/AccountLayout";
import { withoutSsr } from "~/components/SsrLoading";

const Index: React.FC = () => {
  return (
    <AuthWrapper>
      <AccountLayout>
        <DashboardQueryStateProvider>
          <Dashboard />
        </DashboardQueryStateProvider>
      </AccountLayout>
    </AuthWrapper>
  );
};

export default withoutSsr(Index);
