import classnames from "classnames";
import { omit } from "ramda";
import * as React from "react";
import { O } from "ts-toolbelt";

import DashboardContext from "~/components/dashboard/DashboardContext";
import {
  ApiTypes,
  DashboardApiQuery,
  useAuthenticatedFetch
} from "~/utils/http";
import { localeFormat } from "~/utils/numbers";

import StatisticCardSkeleton from "./StatisticCardSkeleton";

const STUB_VALUE = "—";

export interface StatisticCardProps {
  loading?: boolean;
  className?: string;
  value?: React.ReactNode;
  caption: string;
  clarification?: string;
  formatter?: (value: unknown) => React.ReactNode;
  timePeriod?: string;
  error?: boolean;
  testid?: {
    range: React.TestID;
    value: React.TestID;
    noData: React.TestID;
    error: React.TestID;
  };
}

const StatisticCard = React.forwardRef<HTMLDivElement, StatisticCardProps>(
  (
    {
      className,
      value,
      caption,
      formatter,
      loading = false,
      timePeriod,
      error,
      testid
    },
    ref
  ) => {
    const valueToOutput = (): React.ReactNode => {
      if (formatter) {
        return formatter(value);
      }
      if (!value) {
        return STUB_VALUE;
      }
      return typeof value === "number" ? localeFormat(value) : value;
    };

    if (loading || error) {
      return (
        <StatisticCardSkeleton
          ref={ref}
          error={error}
          testid={error ? testid?.error : undefined}
        />
      );
    }

    return (
      <div
        className={classnames(
          className,
          "bg-white flex-1 flex flex-grow flex-col justify-center items-center text-center gap-[16px] min-h-[170px] rounded-[8px] border-2 border-cream-300 px-[32px] py-[16px]"
        )}
        ref={ref}
      >
        <div>
          <h3 className="h1" data-testid={testid?.value}>
            {valueToOutput()}
          </h3>
          <div className="paragraph">{caption}</div>
        </div>
        {timePeriod && (
          <div
            className="explanatoryText !text-gray-600"
            data-testid={testid?.range}
          >
            {timePeriod}
          </div>
        )}
      </div>
    );
  }
);

export default StatisticCard;

export function statisticsProvider<K extends keyof ApiTypes>(
  url: K,
  getValue: (data: ApiTypes[K]["response"]) => number | undefined
) {
  return {
    url,
    getValue: getValue as (
      data: ApiTypes[keyof ApiTypes]["response"]
    ) => number | undefined
  };
}

type ApiStatisticCardProps = O.Merge<
  Omit<StatisticCardProps, "loading" | "value">,
  {
    provider: {
      url: keyof ApiTypes;
      getValue: (
        data: ApiTypes[keyof ApiTypes]["response"]
      ) => number | undefined;
    };
    apiQuery: Omit<DashboardApiQuery, "scale" | "tz">;
  }
>;

export const ApiStatisticCard = React.forwardRef<
  HTMLDivElement,
  ApiStatisticCardProps
>(({ provider, apiQuery, testid, ...props }, ref) => {
  const { selectedPeriod } = React.useContext(DashboardContext);
  const selectedPeriodTitle = selectedPeriod?.title || "";

  const cleanQuery = omit(["scale", "tz"], apiQuery);
  const { url, getValue } = provider;
  const { data, loading, error } = useAuthenticatedFetch(url, cleanQuery, true);

  if (loading || error) {
    return (
      <StatisticCardSkeleton
        ref={ref}
        error={!!error}
        testid={error ? testid?.error : undefined}
      />
    );
  }

  return (
    <StatisticCard
      ref={ref}
      {...props}
      loading={loading}
      value={typeof data !== "undefined" ? getValue(data) : undefined}
      timePeriod={selectedPeriodTitle}
      testid={testid}
    />
  );
});
