import * as React from "react";

import {
  ApiStatisticCard,
  statisticsProvider
} from "~/components/core/StatisticCard/StatisticCard";
import StatisticCardSkeleton from "~/components/core/StatisticCard/StatisticCardSkeleton";
import { SECONDS_IN_MINUTE } from "~/constants/dates";
import { trackEvent } from "~/utils/segment";
import { struct } from "~/utils/struct";

import { LazyDashboardComponent } from "../dashboardQueryState";

const STUB_VALUE = "-";

function formatMins<Type>(value?: Type) {
  let avgLessonLength = STUB_VALUE;
  const numberValue = (value as unknown) as number;

  if (value && Number.isFinite(numberValue)) {
    avgLessonLength = (numberValue / SECONDS_IN_MINUTE).toFixed(0);
  }

  return `${avgLessonLength} min`;
}

const DashboardFilteredStatistics: React.FC = () => {
  React.useEffect(() => {
    trackEvent("load_general_stats", undefined);
  }, []);

  return (
    <>
      <LazyDashboardComponent
        intersecionDebugName="live: Avg. lessons length"
        placeholder={<StatisticCardSkeleton />}
        render={(apiQuery, ref) => (
          <ApiStatisticCard
            ref={ref}
            provider={statisticsProvider(
              "/api/v1/new_stats/live/average_lesson_length/",
              x => x.averageLessonLength
            )}
            apiQuery={apiQuery}
            caption="Average session length"
            formatter={formatMins}
            testid={struct.adminDash.dashboard.tutoring.avgLessonLengthCard}
          />
        )}
      />
      <LazyDashboardComponent
        intersecionDebugName="live: Avg. wait time"
        placeholder={<StatisticCardSkeleton />}
        render={(apiQuery, ref) => (
          <ApiStatisticCard
            ref={ref}
            provider={statisticsProvider(
              "/api/v1/new_stats/live/average_wait_time/",
              x => x.averageWaitTime
            )}
            apiQuery={apiQuery}
            caption="Average wait time for live tutoring"
            formatter={(x: unknown) => `${x ? x : STUB_VALUE} sec`}
            testid={struct.adminDash.dashboard.tutoring.avgWaitTimeCard}
          />
        )}
      />
      <LazyDashboardComponent
        intersecionDebugName="stats: Avg. wl turnaround"
        placeholder={<StatisticCardSkeleton />}
        render={(apiQuery, ref) => (
          <ApiStatisticCard
            ref={ref}
            provider={statisticsProvider(
              "/api/v1/new_stats/wl/average_turnaround_time/",
              x => x.averageTurnaroundTime
            )}
            apiQuery={apiQuery}
            caption="Average paper turnaround time"
            formatter={(x: unknown) => `${x ? x : STUB_VALUE} min`}
            testid={struct.adminDash.dashboard.generalUsage.avgTutorRatingCard}
          />
        )}
      />
    </>
  );
};

export default DashboardFilteredStatistics;
