import { omit } from "ramda";
import React, { Fragment, useState } from "react";

import { useRequiredAuthContext } from "~/components/auth/common";
import Select from "~/components/core/Select";
import { WidgetContentNew } from "~/components/core/WidgetCard/WidgetCardContent";
import { gradeOptions } from "~/constants/filters";
import { useAuthenticatedFetch } from "~/utils/http";
import { trackEvent } from "~/utils/segment";
import { struct } from "~/utils/struct";

import { createDashboardLazyWidget } from "../createDashboardLazyWidget";
import {
  SubjectStatsHeader,
  SubjectStatsRow,
  TableHeader
} from "./SubjectStatsTableComponents";

const DashboardSubjectStats = createDashboardLazyWidget({
  displayName: "DashboardSubjectStats",
  className: "flex-1 p-[24px]",
  useRender: (apiQuery, resetFilters) => {
    const { selectedUmbrella } = useRequiredAuthContext();
    const [subjOpen, setSubjOpen] = useState<{ [key: string]: boolean }>({});
    const [grade, setGrade] = useState("all");

    const { data, loading, error, refetch } = useAuthenticatedFetch(
      "/api/v1/new_stats/subject_stats/",
      omit(["scale", "subjects", "lessons"], {
        ...apiQuery,
        grades: grade === "all" ? undefined : parseInt(grade)
      }),
      true
    );

    const handleSubjOpen = (subjName: string) => {
      if (subjOpen[subjName]) {
        setSubjOpen({ ...subjOpen, [subjName]: false });
      } else {
        setSubjOpen({ ...subjOpen, [subjName]: true });
      }
    };

    const changeGrade = (grade: string) => {
      setGrade(grade);
      requestAnimationFrame(() => refetch());
      trackEvent("ll_subject_stats_change_grade", { grade });
    };

    const handleReset = () => {
      setGrade("all");
      setSubjOpen({});
      resetFilters();
      trackEvent("ll_subject_stats_reset", undefined);
    };

    React.useEffect(() => {
      trackEvent("ll_load_subject_stats_chart", undefined);
    }, []);

    const testid = struct.adminDash.dashboard.tutoring.subjectStats;
    return (
      <div className="flex flex-col">
        <SubjectStatsHeader />

        <WidgetContentNew
          loading={loading}
          error={error}
          hasData={data && data.length > 0 ? true : undefined}
          className="min-h-[245px] mt-[16px]"
          resetFilters={handleReset}
          testid={testid}
        >
          <div className="w-[175px] my-[16px] pl-[2px]">
            {selectedUmbrella.partnerType === "k-12" && (
              <Select
                label="Session grade level"
                options={gradeOptions}
                onChange={changeGrade}
                selectedOption={gradeOptions.find(x => x.value === grade)}
                optionsClassname="max-h-36"
                testidButton={testid.gradeSelect.menuButton}
                testidOption={testid.gradeSelect.grade}
              />
            )}
          </div>

          <table className="table-auto">
            <TableHeader isK12={selectedUmbrella.partnerType === "k-12"} />
            <tbody className="text-right paragraph">
              {data &&
                data.map((item, index) => (
                  <Fragment key={`0-${index}-${item.subject}`}>
                    <SubjectStatsRow
                      data={item}
                      handleSubjOpen={handleSubjOpen}
                      handleGrade={changeGrade}
                      isOpen={subjOpen[item.subject]}
                    />
                    {subjOpen[item.subject] &&
                      item.children.map(child => (
                        <Fragment key={`1-${index}-${child.subject}`}>
                          <SubjectStatsRow
                            data={child}
                            level={2}
                            handleSubjOpen={handleSubjOpen}
                            handleGrade={changeGrade}
                            isOpen={subjOpen[child.subject]}
                          />
                          {subjOpen[child.subject] &&
                            child.children.map(child2 => (
                              <SubjectStatsRow
                                key={`2-${index}-${child2.subject}`}
                                data={child2}
                                level={3}
                                handleSubjOpen={handleSubjOpen}
                                handleGrade={changeGrade}
                              />
                            ))}
                        </Fragment>
                      ))}
                  </Fragment>
                ))}
            </tbody>
          </table>
        </WidgetContentNew>
      </div>
    );
  }
});

export default DashboardSubjectStats;
