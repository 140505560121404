import * as React from "react";

import { useRequiredAuthContext } from "~/components/auth/common";
import { SegmentedButtons } from "~/components/core/Button";
import { PERIODS } from "~/constants/dates";
import { useReduxDispatch, useReduxSelector } from "~/redux/hooks";
import { setCumulative } from "~/redux/reducers/charts";
import { schoolYearFilterLabel } from "~/utils/schoolYearHelpers";
import { trackEvent } from "~/utils/segment";

import { useDashboardQueryState } from "../dashboardQueryState";

const GeneralUsageHeader = () => {
  const dispatch = useReduxDispatch();
  const cumulative = useReduxSelector(state => state.charts.cumulative);

  const { schoolYearStartDate } = useRequiredAuthContext();
  const [{ period: periodFilter }] = useDashboardQueryState();
  const selectedPeriod = PERIODS.find(({ period }) => period === periodFilter);

  const handleToggle = () => {
    dispatch(setCumulative(!cumulative));
    trackEvent(
      cumulative ? "graph_frequency_click" : "graph_cumulative_click",
      undefined
    );
  };

  return (
    <div className="mb-[40px] flex w-full items-center justify-between">
      <div className="flex items-center gap-[16px]">
        <div className="h2">Overview</div>
        <div className="explanatoryText !text-gray-600">
          {selectedPeriod?.title
            ? schoolYearFilterLabel(selectedPeriod.title, schoolYearStartDate)
            : ""}
        </div>
      </div>
      <SegmentedButtons
        on={!cumulative}
        toggle={handleToggle}
        titlePrimary="Frequency"
        titleSecondary="Cumulative"
      />
    </div>
  );
};

export default GeneralUsageHeader;
