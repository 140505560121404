import * as React from "react";

import FilterToolbar from "~/components/core/FilterToolbar";
import { PERIOD } from "~/constants/dates";
import { customPeriods } from "~/constants/filters";
import { Period, Range } from "~/declarations/filters";
import { trackEvent } from "~/utils/segment";

import DashboardContext from "../DashboardContext";
import { useDashboardQueryState } from "../dashboardQueryState";
import DashboardCustomFilters from "./DashboardCustomFilters";

interface Props {
  rangeFilter?: Range;
}

const DashboardFilterToolbar = React.forwardRef<HTMLDivElement, Props>(
  ({ rangeFilter }, ref) => {
    const [, setQueryState] = useDashboardQueryState();

    const { selectedPeriod } = React.useContext(DashboardContext);

    const handleChangePeriod = React.useCallback(
      (period: Period, range: Range) => {
        setQueryState({
          period: period.period,
          fromDt: customPeriods.includes(period.period) ? range[0] : null,
          tillDt: customPeriods.includes(period.period) ? range[1] : null
        });

        trackEvent("change_date_range", {
          type: period.period,
          from: JSON.stringify(range[0]),
          to: JSON.stringify(range[1])
        });
      },
      [setQueryState]
    );

    return (
      <FilterToolbar
        ref={ref}
        selectedTab={selectedPeriod?.period || PERIOD.last30Days}
        rangeFilter={rangeFilter}
        onChange={handleChangePeriod}
        className="dashboardFilterToolbar"
        isDashboard
      >
        <DashboardCustomFilters />
      </FilterToolbar>
    );
  }
);

export default DashboardFilterToolbar;
