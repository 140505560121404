import React, { MutableRefObject } from "react";

export interface DashboardGuideContextProps {
  headerRef?: MutableRefObject<HTMLDivElement | null>;
  filtersRef?: MutableRefObject<HTMLDivElement | null>;
  generalReportsRef?: MutableRefObject<HTMLDivElement | null>;
  subjectStatsRef?: MutableRefObject<HTMLDivElement | null>;
  ratingsRef?: MutableRefObject<HTMLDivElement | null>;
  powerUsersRef?: MutableRefObject<HTMLDivElement | null>;
}

export default React.createContext<DashboardGuideContextProps>({});
